import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import { Seo } from "../../components/seo"
import Subscribe from "../../components/subscribe"
import Footer from "../../components/footer"

const EpisodePage = () => (
    <Layout>
        <div className="episode-detail-wrapper">
            <header>
                <StaticImage
                    src="../../images/the-sentinel-poster.jpeg"
                    quality={95}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="A poster for the 1977 haunted house movie, The Sentinel"
                    className="img-fluid"
                />
            </header>
            <div className="episode-detail-body container">
                <div className="row episode-detail-title text-center py-5">
                    <h1>Episode 05</h1>
                    <h2>The Sentinel</h2>
                    <h3>April 17, 2023</h3>
                </div>
                <div className="row episode-detail-plot boxed-in">
                    <div class="indent-this py-5">
                        <p><b>Director:</b> Michael Winner // <b>Starring:</b> Cristina Raines &amp; Chris Sarandon</p>
                        <div className="player">
                            <iframe src="https://podcasters.spotify.com/pod/show/bryan-jude-white/embed/episodes/05-The-Sentinel-e2244iv" height="102px" width="400px" frameborder="0" scrolling="no" title="Bring Me The Axe Horror Podcast episode 5, The Sentinel"></iframe>
                        </div>
                        <p><Link to="/transcripts/the-sentinel">Read the episode transcript</Link></p>
                        <p>This week Bryan and Dave take a look at the 1977 Michael Winner clone of The Omen/Rosemary's Baby, The Sentinel. Ostensibly the most Catholic horror movie ever made, it's also about as paranoid as a horror movie can be. One damned soul, alone must stand watch over the gateway to Hell and Alison Parker is next in line to take on the mantle. Along the way, literally everyone will manipulate her toward their own ends.</p>
                    </div>
                </div>
                <div className="row">
                    <Subscribe />
                </div>
                <div className="row previous-next indent-this">
                    <ul>
                        <li><Link to="/episodes/the-howling">Next Episode</Link></li>
                        <li><Link to="/episodes/maniac">Previous Episode</Link></li>
                    </ul>
                </div>
                <Footer />
            </div>
        </div>
    </Layout>
)

export default EpisodePage

export const Head = () => (
    <Seo />
)